var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-container", [
    _c("p", { staticClass: "pull-right" }, [
      _vm._v(
        " Details: " + _vm._s(_vm.clicked.x) + "x" + _vm._s(_vm.clicked.y)
      ),
      _c("br"),
      _vm._v(" HexCounter: " + _vm._s(_vm.hexCounter)),
      _c("br"),
      _vm._v(" Selected: "),
      _c(
        "textarea",
        {
          staticStyle: {
            width: "100%",
            height: "500px",
            color: "#fff",
            border: "1px solid #fff",
          },
          attrs: { readonly: "" },
        },
        [
          _vm._v(
            "[" +
              _vm._s(
                _vm.selectedHexes
                  .map((a) => {
                    return JSON.parse(a)
                  })
                  .join("],[")
              ) +
              "]"
          ),
        ]
      ),
    ]),
    _c("div", [_vm._v("fps: "), _c("span", { attrs: { id: "fps" } })]),
    _c("canvas", { attrs: { id: "canvas", "data-paper-hidpi": "off" } }),
    _c("img", {
      staticStyle: { display: "none" },
      attrs: { id: "map-img", src: "/img/map/map-full_1700.webp" },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }