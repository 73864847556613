<template>
    <v-container>

        <p class="pull-right">
            Details: {{ clicked.x }}x{{ clicked.y }}<br/>
            HexCounter: {{ hexCounter }}<br/>
            Selected: <textarea readonly style="width: 100%;height: 500px;color:#fff;border: 1px solid #fff">[{{ selectedHexes.map((a)=>{return JSON.parse(a)}).join('],[') }}]</textarea>
        </p>
        <div>fps: <span id="fps"></span></div>
        <canvas id="canvas" data-paper-hidpi="off" />
        <img id="map-img" style="display: none" src="/img/map/map-full_1700.webp"/>

    </v-container>
</template>

<!--http://paperjs.org/reference/path/#segments-->

<script>
import paper from "paper";
import * as Hammer from 'hammerjs'

let selectedHex = null;
let mouseClickDownPoint = null;

export default {
    data() {
        return {
            canvas: null,
            clicked: {
                x: null,
                y: null,
            },
            hexCounter: 0,
            selectedHexes: [],
        }
    },
    created: function () {
        // this.canvas = document.getElementById('canvas');
        // this.ctx = canvas.getContext('2d');

    },
    mounted: function () {
        setTimeout(() => {
            this.draw(800, 800);
        }, 500);

    },
    computed: {
        web3() {
            return this.$store.state.web3;
        },
        isWalletConnected() {
            return this.web3.isWalletConnected;
        }
    },
    methods: {
// 158^2 = 24964
// 160^2 = 25600
        randomInt(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min + 1) + min);
        },
        randomElement(elements = []) {
            let elementIndex = this.randomInt(0, elements.length - 1);
            return elements[elementIndex];
        },

        draw() {
            const size = 10;
            // const size = 20;
            const amountX = 100;
            const amountY = 80;
            const mapScale = 0.19277;
            const mapBgScale = 0.58;
            const intersectCorrection = 1; //+ more inside map
            const mapHexYPosCorrection = 0;
            const mapHexXPosCorrection = 1;
            const mapPosition = {x: 500, y: 350};
            const zoomRatio = 0.2;

            let hexes = [];

            let canvas = document.getElementById('canvas');

            let project = new paper.PaperScope();
            project.setup('canvas');


            let hammertime = new Hammer(canvas);
            hammertime.get('pinch').set({enable: true});
            hammertime.on('pinch', (event) => {
                project.view.zoom = event.scale;
                // paperScope.view.zoom = paperScope.view.zoom * newScale;
            });

            const toolPan = new paper.Tool()
            toolPan.activate()

            project.paper.project.importSVG('/img/map/parts/8.svg', (map) => {
                map.scale(mapScale);
                map.position = new paper.Point(mapPosition.x, mapPosition.y);
                map.fillColor = 'red';
                map.opacity = 0;
                map.sendToBack();
                const mapPath = map.children[1];

                let ras = new paper.Raster('map-img');
                ras.position = new paper.Point(mapPosition.x, mapPosition.y);
                ras.scale(mapBgScale);

                const pointO = new project.Point(0, 0);
                let hexO = new project.Path.RegularPolygon(pointO, 6, size / 2 + 1);
                hexO.fillColor = 'white';
                // hexO.strokeColor = 'white';
                hexO.opacity = 0.5;
                hexO.aX = 0;
                hexO.aY = 0;

                let tmpHexCounter = 0;
                const smallSize = size / 4 + intersectCorrection;
                for (let y = 0; y < amountY; y++) {
                    for (let x = 0; x < amountX; x++) {
                        const top = y * (size + mapHexYPosCorrection);
                        const left = x * (size + mapHexXPosCorrection) + (y % 2 ? (size + mapHexXPosCorrection) / 2 : 0);
                        const point = new project.Point(left, top);
                        const point1 = new project.Point(left + smallSize, top);
                        const point2 = new project.Point(left - smallSize, top);
                        const point3 = new project.Point(left, top + smallSize);
                        const point4 = new project.Point(left, top - smallSize);

                        if (
                            // true
                            mapPath.contains(point1)
                            && mapPath.contains(point2)
                            && mapPath.contains(point3)
                            && mapPath.contains(point4)
                        ) {
                            let hex = hexO.clone();
                            hex.position = point;
                            hex.aX = x;
                            hex.aY = y;
                            // hex.fillColor = this.randomElement(['white', 'white', 'blue', 'purple', 'orange', 'white']);
                            tmpHexCounter++;
                            hexes.push([x, y]);
                        }
                    }
                }
                this.hexCounter = tmpHexCounter;
                hexO.remove();

                console.log(JSON.stringify(hexes));

                toolPan.onMouseDown = (event) => {
                    this.mouseClickDownPoint = event.event;
                };
                toolPan.onMouseUp = (event) => {
                    if (event.event === null || parseInt(event.event.x) !== parseInt(this.mouseClickDownPoint.x) || parseInt(event.event.y) !== parseInt(this.mouseClickDownPoint.y)) {
                        return
                    }
                    const point = event.point;
                    this.onMouseUp(point, project, mapPath, size);
                };

                toolPan.onMouseDrag = (event) => {
                    const delta = event.downPoint.subtract(event.point);
                    project.view.translate(delta.multiply(-1));
                }

                canvas.addEventListener('wheel', event => {
                    let paper = project;
                    let newZoom = paper.view.zoom;
                    let oldZoom = paper.view.zoom;

                    if (event.deltaY < 0) {
                        newZoom = paper.view.zoom * (1 + zoomRatio);
                    } else {
                        newZoom = paper.view.zoom * (1 - zoomRatio);
                    }

                    const beta = oldZoom / newZoom;

                    const mousePosition = new paper.Point(event.offsetX, event.offsetY);

                    //viewToProject: gives the coordinates in the Project space from the Screen Coordinates
                    const viewPosition = paper.view.viewToProject(mousePosition);

                    const mpos = viewPosition;
                    const ctr = paper.view.center;

                    const pc = mpos.subtract(ctr);
                    const offset = mpos.subtract(pc.multiply(beta)).subtract(ctr);

                    paper.view.zoom = newZoom;
                    paper.view.center = paper.view.center.add(offset);

                    event.preventDefault();
                    paper.view.draw();
                });


                this.displayFPS();
            })
        },

        onMouseUp(point, project, mapPath, size) {
            const hitResult = project.paper.project.hitTest(point, {
                segments: false,
                stroke: true,
                fill: true,
                tolerance: 2
            });
            if (hitResult && 'fill' === hitResult.type) {
                let path = hitResult.item;
                if (path === mapPath) {
                    return;
                }

                this.clicked.x = hitResult.item.aX;
                this.clicked.y = hitResult.item.aY;

                const newElem = JSON.stringify([hitResult.item.aX, hitResult.item.aY]);
                const duplicatedElemIndex = this.selectedHexes.indexOf(newElem);
                if (duplicatedElemIndex === -1) {
                    this.selectedHexes.push(newElem);
                    let hexS = new project.Path.RegularPolygon(new paper.Point(hitResult.item.position.x, hitResult.item.position.y), 6, size / 2 + 1);
                    hexS.fillColor = 'green'
                    hexS.aX = hitResult.item.aX;
                    hexS.aY = hitResult.item.aY;
                    hexS.selectedHex = true;
                    this.selectedHex = hexS;
                } else {
                    this.selectedHexes.splice(duplicatedElemIndex, 1);
                    if(hitResult.item.selectedHex){
                        hitResult.item.remove();
                    }
                }
            }
            this.mouseClickDownPoint = null;
        },

        displayFPS() {
            window.requestAnimFrame = (function () {
                return window.requestAnimationFrame ||
                    window.webkitRequestAnimationFrame ||
                    window.mozRequestAnimationFrame ||
                    window.ieRequestAnimationFrame ||
                    function (callback) {
                        window.setTimeout(callback, 1000 / 60);
                    };
            })();
            var fpsElement = document.getElementById("fps");
            var then = Date.now() / 1000;  // get time in seconds
            var render = function () {
                var now = Date.now() / 1000;  // get time in seconds

                // compute time since last frame
                var elapsedTime = now - then;
                then = now;

                // compute fps
                var fps = 1 / elapsedTime;
                fpsElement.innerText = fps.toFixed(2);

                requestAnimFrame(render);
            };
            render();
        }
    }
}
</script>

<style scoped>
.container {
    width: 100%;
    height: 90%;
}

#canvas {
    width: 80%;
    height: 100%;
    border: 1px solid red;
    background: #f0af65;
}
</style>
